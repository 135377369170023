import React from 'react';
import styles from './school-choice.module.module.scss';
import { getStudent } from 'components/pages/schools-results-v2/utils/get-student';
import propTypes from 'prop-types';

const RowContent = ({ content, name, description }) => {
  return (
    <div className={styles.table_three_col}>
      <div className={styles.table_row_title}>{content}</div>
      <div className="personalyti_description">{name}</div>
      <div className="personalyti_description">{description}</div>
    </div>
  );
};

export const SchoolChoice = ({ educationalApproach }) => {
  const student = getStudent();

  return (
    <>
      <h3 className="heading-1 font-600 spacing-mb-20">
        <span className="underline">{student}’s</span> School Choice
      </h3>
      <p>
        Choosing the right school is crucial for {student}'s development. Taking into account her personality and
        interests, her ideal learning environment would look something like this:
      </p>
      <div className="w-100">
        <h4 className={styles.table_title_gray}>
          <span>{student}’s</span> Optimal Learning Approach
        </h4>
        {educationalApproach.map(({ keyName, name, description }) => (
          <RowContent key={keyName} content={keyName} name={name} description={description} />
        ))}
      </div>
    </>
  );
};
SchoolChoice.propTypes = {
  educationalApproach: propTypes.array,
};
RowContent.propTypes = {
  name: propTypes.string,
  content: propTypes.string,
  description: propTypes.string,
};
