import React from 'react';
import styles from './strengths-areas-improvement.module.scss';
import { CircleSolid16 } from '@carbon/icons-react';
import { opportunitiesDescription, strengthsDescription } from 'components/pages/personality-match/personality-mapping';
import propTypes from 'prop-types';

export const StrengthsAreasImprovement = ({ strengthsSkills, weaknessesSkills }) => {
  return (
    <div className={styles.row}>
      <Skills name="Strengths" skillList={strengthsSkills} colorType="on" description={strengthsDescription} />
      <Skills
        name="Growth Opportunities"
        skillList={weaknessesSkills}
        colorType="off"
        description={opportunitiesDescription}
      />
    </div>
  );
};

const getSkillScore = (value) => {
  let score = value / 20;
  score = score.toString();
  const values = score.split('.');
  const entire = Number(values[0]);
  const decimal = values[1] ? Number(values[1]) : null;

  return {
    entire,
    decimal: decimal === 5 ? 50 : decimal,
  };
};

const skillLevels = ['Minor', 'Moderate', 'Notable', 'Major', 'Dominant'];

const LevelBar = ({ name, width, colorType }) => {
  const paletteColor = colorType === 'on';
  return (
    <div className={styles.barLevel}>
      <div className={`${styles.level_bar_base} ${styles[paletteColor ? 'bgOn' : 'bgOff']}`}>
        <div className={`${styles.level_bar_dark} ${styles[paletteColor ? 'fillOn' : 'fillOff']}`} style={{ width }} />
      </div>
      <CircleSolid16 className={`${styles.level_dot} ${styles[paletteColor ? 'colorOn' : 'colorOff']}`} />
      <div className={styles.level_title}>{name}</div>
    </div>
  );
};

const getPercentageScore = (score, index) => {
  if (score.decimal) {
    if (score.entire + 1 === index) return `${score.decimal}%`;
  }

  return index > score.entire ? '0' : '100%';
};

const Skills = ({ name, skillList, colorType, description }) => {
  return (
    <div className={styles.column}>
      <h3 className={styles.list_title}>{name}</h3>
      <div className={styles.skillList}>
        {Object.entries(skillList).map(([skill, value], index) => {
          const score = getSkillScore(value);
          return (
            <div key={`${index + 1} - ${skill}`} className={styles.skillItem}>
              <div className={styles.skillTitle}>
                <h6>
                  {index + 1}.- {skill}
                </h6>
              </div>
              <p className={styles.skillDescription}>{description[skill] || ''}</p>
              <div className={styles.range_level_container}>
                {skillLevels.map((level, index) => (
                  <LevelBar
                    key={index}
                    name={level}
                    width={getPercentageScore(score, index + 1)}
                    colorType={colorType}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
LevelBar.propTypes = {
  name: propTypes.string,
  width: propTypes.string,
  colorType: propTypes.string,
};
StrengthsAreasImprovement.propTypes = {
  strengthsSkills: propTypes.object,
  weaknessesSkills: propTypes.object,
};
Skills.propTypes = {
  name: propTypes.string,
  colorType: propTypes.string,
  skillList: propTypes.object,
  description: propTypes.object,
};
