import { useToggle } from 'hooks/useToggle';
import { Search16 } from '@carbon/icons-react';
import { useClickOutside } from 'hooks/useClickOutside';
import styles from './place-searcher.module.scss';
import propTypes from 'prop-types';

export const PlaceSearcher = ({ places, searchPlace, onChange, placeholder, onClick }) => {
  const [isDropOpen, toggleDrop] = useToggle(false);

  const ref = useClickOutside(() => {
    if (isDropOpen) toggleDrop();
  });

  return (
    <div className={styles.search} ref={ref}>
      <input
        type="text"
        id="search_places"
        name="search_places"
        placeholder="Search by city"
        value={searchPlace}
        onChange={onChange}
        onFocus={() => !isDropOpen && toggleDrop()}
      />
      <div className={styles.icon}>
        <Search16 />
      </div>
      {isDropOpen && places?.length > 0 && (
        <ul className={styles.searchList}>
          {places?.map((place) => (
            <li
              key={place.value}
              className={styles.item}
              onClick={() => {
                onClick(place);
                toggleDrop();
              }}>
              {place.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
PlaceSearcher.propTypes = {
  places: propTypes.array,
  searchPlace: propTypes.string,
  onChange: propTypes.func,
  placeholder: propTypes.string,
};
