import React from 'react';
import { FeatureChip, FeatureSection } from '..';
import { Bus24, Money24, Apple24 } from '@carbon/icons-react';
import { useOfferTransportation, getStudentsPerTeachersRatio } from '../../utils/get-insights-features';

export const GeneralFeatures = ({ school, generalFeatureData }) => {
  const {
    transportation_offered: hasTransportation,
    features,
    number_of_teachers: numberOfTeachers,
    number_of_students: numberOfStudents,
  } = school;

  const transportation = useOfferTransportation({ hasTransportation, features });

  const ratio = getStudentsPerTeachersRatio({ numberOfTeachers, numberOfStudents });
  console.log(generalFeatureData);

  return (
    (generalFeatureData.length > 0 || hasTransportation || ratio !== undefined) && (
      <FeatureSection title="General School Features" features={generalFeatureData}>
        {hasTransportation && <FeatureChip icon={<Bus24 />} label="Transportation" value={transportation} />}
        {ratio && <FeatureChip icon={<Apple24 />} label="Student Ratio" value={ratio} />}{' '}
      </FeatureSection>
    )
  );
};

export const TuitionPrograms = ({ schoolTuition, tuitionFeatures }) => {
  return (
    <FeatureSection title="Tuition" features={tuitionFeatures}>
      {schoolTuition && <FeatureChip icon={<Money24 />} label="Tuition" value={schoolTuition} />}
    </FeatureSection>
  );
};
