import { ChevronDown16, ChevronUp16 } from '@carbon/icons-react';
import propTypes from 'prop-types';
import styles from './distance-controller.module.scss';

export const DistanceController = ({ radius, handleDistance }) => {
  return (
    <div className={styles.distance}>
      <span>{radius} miles</span>
      <div className={styles.groupButtons}>
        <button onClick={() => handleDistance('plus')}>
          <ChevronUp16 />
        </button>
        <button onClick={() => handleDistance('minus')}>
          <ChevronDown16 />
        </button>
      </div>
    </div>
  );
};
DistanceController.propTypes = {
  radius: propTypes.string.isRequired,
  handleDistance: propTypes.func.isRequired,
};
