import { HorizontalCard } from 'components/card-school/horizontal-card/horizontal-card';
import { VerticalCard } from 'components/card-school/vertical-card/vertical-card';
import { BannerCard } from '..';
import styles from './school-list.module.scss';
import { getStudent } from '../../utils/get-student';
import { useDispatch } from 'react-redux';
import { openContact } from 'redux/slices/contact-school.slice';
import propTypes from 'prop-types';
import { setMultipleContact, setMultipleSchoolsIds, setSchoolToContact } from 'redux/slices/school-results.slice';

export const SchoolsList = ({ schools, sponsored, loadingSchools, successSchools, errorSchools }) => {
  const student = getStudent();
  const dispatch = useDispatch();

  const handleContact = (school, isMultiple = false) => {
    dispatch(openContact());
    dispatch(setMultipleContact(isMultiple));

    if (isMultiple) {
      const topSchools = schools.slice(0, 3).map(({ id }) => id);
      dispatch(setMultipleSchoolsIds(topSchools));
      dispatch(setSchoolToContact({}));
      return;
    }

    dispatch(setSchoolToContact(school));
    dispatch(setMultipleSchoolsIds([]));
  };

  if (loadingSchools) {
    return (
      <div className={styles.background}>
        <LoadingList />
      </div>
    );
  }

  if (errorSchools) {
    return (
      <div className={styles.background}>
        <ErrorSchools message="Sorry, an error has appeared, please try it again." />
      </div>
    );
  }

  return (
    <>
      {successSchools && schools.length === 0 && (
        <ErrorSchools message="No schools founds with this criteria. Try changing your search parameters." />
      )}

      {sponsored.length > 0 && <SponsoredSchools sponsored={sponsored} handleContact={handleContact} />}

      <div className={styles.mainList}>
        {schools.map((school, index) => {
          return (
            <div key={school.id}>
              <HorizontalCard
                school={school}
                rank={index <= 2 ? `#${index + 1} BEST MATCH FOR ${student}` : null}
                handleContact={() => handleContact(school)}
              />
              {index === 2 && (
                <BannerCard
                  title="Connect with Your Top School Choices!"
                  description="Learn more about the best schools for your child. Get connected and gather detailed information to make the best decision."
                  buttonLabel="Request Information From My Top Results!"
                  onClick={() => handleContact({}, true)}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const SponsoredSchools = ({ sponsored, handleContact }) => {
  if (sponsored.length > 1) {
    return (
      <div className={styles.sponsoredGrid}>
        {sponsored.map((school) => (
          <VerticalCard
            key={`sponsored school: ${school.id}`}
            school={school}
            handleContact={() => handleContact(school)}
          />
        ))}
      </div>
    );
  }
  return sponsored.map((school) => (
    <div key={`list sponsored school: ${school.id}`} className={styles.mainList}>
      <HorizontalCard school={school} isSponsored handleContact={() => handleContact(school)} />
    </div>
  ));
};

const LoadingList = () => {
  return (
    <div className={styles.mainList}>
      {Array(3)
        .fill('')
        .map((_, idx) => (
          <div key={idx} className={styles.skeletonCard}>
            <div />
            <div />
          </div>
        ))}
    </div>
  );
};

const ErrorSchools = ({ message }) => {
  return (
    <div className={styles.notFound}>
      <h4>{message}</h4>
      <img src="https://static.schola.com/errors/Error4041-1.png" alt="Robot not found this page" />
    </div>
  );
};
SponsoredSchools.propTypes = {
  sponsored: propTypes.array,
  handleContact: propTypes.func,
};
ErrorSchools.propTypes = {
  message: propTypes.string,
};
SchoolsList.propTypes = {
  schools: propTypes.array,
  sponsored: propTypes.array,
  loadingSchools: propTypes.bool,
  successSchools: propTypes.bool,
  errorSchools: propTypes.bool,
};
