import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { ConnectionReceive16 } from '@carbon/icons-react';
import { handleRouteSchool } from '../utils';
import { _getFeatures } from 'controllers/features/features_controller';
import { SchoolPhoto } from '../school-photo/school-photo';
import { useToggle } from 'hooks/useToggle';
import styles from '../school-card.module.scss';
import { FeaturesMatched } from 'components/features-matched/features-matched';
import Button from 'components/button/Button';
import { SchoolInfo } from '../school-info/school-info';

export const VerticalCard = ({ school, handleContact }) => {
  const {
    name,
    address,
    city,
    state,
    distance,
    type,
    grades,
    open_enrollment: isOpenEnrollment,
    claimed,
    featuresMatched,
    profile_image: image,
    states_served: statesServed,
  } = school;
  const [completeView, toggleCompleteView] = useToggle(false);

  return (
    <div className={styles.item}>
      <div className={styles.card_image_container}>
        <SponsoredTag />
        {/* <div className={styles.card_favorite}>
          <HeartOutlined style={{ fontSize: '22px' }} />
        </div> */}
        <SchoolPhoto direction="vertical" photo={image} />
      </div>
      <div className={styles.content}>
        <div className={styles.flex_content} style={featuresMatched?.length === 0 ? { height: '170px' } : {}}>
          <div>
            <div className={styles.school_name}>
              <h5 className={styles.vertical_school_title}>{name}</h5>
              {claimed && <CheckCircleFilled style={{ color: '#0099CC', marginTop: '8px' }} />}
            </div>
            <div>
              <SchoolInfo
                address={`${address}, ${city}, ${state}`}
                distance={distance}
                grades={grades}
                type={type}
                statesServed={statesServed}
              />
              {isOpenEnrollment && <EnrollingTag />}
            </div>
          </div>
          <FeaturesMatched
            className={completeView && styles.matchOverlay}
            featuresMatched={featuresMatched}
            completeView={completeView}
            toggleCompleteView={toggleCompleteView}
            showElements={2}
          />
        </div>
        <div className="spacing-mt-12">
          <Button className="spacing-mb-12" fullWidth color="primary" onClick={handleContact}>
            Request Information
          </Button>
          <Button fullWidth color="secondary" onClick={() => handleRouteSchool(school)}>
            View Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SponsoredTag = () => {
  return (
    <div className={styles.card_bestmatch}>
      <img src="https://static.schola.com/scholamatch/bestmatch-cup.svg" alt="Schola Icon" />
      Sponsored
    </div>
  );
};

const EnrollingTag = () => {
  return (
    <div className={styles.school_enrolling}>
      <p className="school-info-chip spacing-mt-16">
        <ConnectionReceive16 /> &nbsp; <span>Enrolling</span>
      </p>
    </div>
  );
};
