import React from 'react';
import propTypes from 'prop-types';

export const EffectiveOptions = ({ alternatives }) => {
  return (
    <div className="other-effective-options spacing-mt-20">
      <p>Other effective options include:</p>
      <ul>
        {alternatives.map((option) => (
          <li key={option.key}>
            <b>{option.key}</b> {option.value}
          </li>
        ))}
      </ul>
    </div>
  );
};
EffectiveOptions.propTypes = {
  alternatives: propTypes.array,
};
