import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeMultipleFeatures, setCoed } from 'redux/slices/school-results.slice';
import propTypes from 'prop-types';
import styles from './coed-status.module.scss';

export const CoedStatus = ({ coed, handleFeatureSelection, featuresSelection }) => {
  const dispatch = useDispatch();
  const { coedSelection } = useSelector((state) => state.schoolResults);

  const gendersIds = useMemo(() => {
    const genders = coed?.features.map((gender) => gender.id);
    return genders;
  }, [coed]);

  const handleGender = (id) => {
    const remainingIds = gendersIds.filter((genderId) => genderId !== id);

    handleFeatureSelection(id);
    dispatch(removeMultipleFeatures(remainingIds));

    dispatch(setCoed(id));
  };

  return (
    <div className={styles.buttonGroup} role="group" aria-label="Coed Status Select">
      {coed?.features.map((gender) => (
        <button
          key={gender.id}
          id={gender.id}
          className={`${styles.button_radio} ${coedSelection === gender.id ? styles.checked : ''}`}
          onClick={() => handleGender(gender.id)}>
          <span>{gender.id === 501 ? 'Coed' : gender.name}</span>
        </button>
      ))}
    </div>
  );
};
CoedStatus.propTypes = {
  coed: propTypes.object,
  handleFeatureSelection: propTypes.func,
  featuresSelection: propTypes.array,
};
