import { useEffect, useState } from 'react';

export const useAutoFillForm = (location) => {
  const [autoFillForm, setAutoFillForm] = useState({});

  useEffect(() => {
    const infoExternalForm = JSON.parse(localStorage.getItem('scholamatchinfoV5'));
    if (infoExternalForm) {
      const parentName = infoExternalForm.parentName;
      setAutoFillForm({
        firstName: parentName?.split(' ')[0] || '',
        lastName: parentName?.split(' ')[1] || '',
        email: infoExternalForm.email || '',
        phone: infoExternalForm.phone || '',
        studentName: infoExternalForm.studentName || '',
        grade: location.query.grades || '',
        zipcode: location.query.zip || '',
      });
    }
  }, []);

  return autoFillForm;
};
