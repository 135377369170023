import React from 'react';
import {
  Bot20,
  Calculation20,
  Chemistry20,
  Cognitive20,
  Finance20,
  Forum20,
  Analytics20,
  Chip20,
  Hourglass20,
  Flow20,
  NetworkEnterprise20,
  ShareKnowledge20,
  Idea20,
  Book20,
  WatsonHealthBrushFreehand20,
  Events20,
  Pen20,
  Music20,
  Theater20,
  RequestQuote20,
  WatsonHealthDna20,
  Education20,
  Stethoscope20,
  Basketball20,
  TaskTools20,
  Rocket20,
  Friendship20,
  Gamification20,
  Code20,
  EarthFilled20,
  PaintBrush20,
  Microphone20,
  UserMilitary20,
  Hospital20,
  Taxi20,
  Tree20,
  Aperture20,
  FaceActivated20,
  Trophy20,
} from '@carbon/icons-react';
import { FeatureListItem } from './components/feature-list-item/feature-list-item';
import propTypes from 'prop-types';
import styles from './feature-lists.module.scss';

export const FeatureLists = ({ subjects, extracurricular }) => {
  return (
    <div className={styles.row}>
      <List name="Subject" items={subjects} />
      <List name="Extracurricular Activities" items={extracurricular} />
    </div>
  );
};

const List = ({ name, items }) => {
  return (
    <div className={styles.background}>
      <h3 className={`${styles.list_title} spacing-mb-16`}>{name}</h3>
      <div className={styles.list}>
        {items.map((item) => (
          <FeatureListItem key={item}>
            {iconMapping[item]}
            <h6>{item}</h6>
          </FeatureListItem>
        ))}
      </div>
    </div>
  );
};

const iconMapping = {
  Science: <Chemistry20 />,
  Technology: <Chip20 />,
  Mathematics: <Calculation20 />,
  History: <Hourglass20 />,
  Philosophy: <Cognitive20 />,
  Economics: <Analytics20 />,
  Logic: <Flow20 />,
  Business: <NetworkEnterprise20 />,
  Leadership: <ShareKnowledge20 />,
  Debate: <Forum20 />,
  Entrepreneurship: <Idea20 />,
  Literature: <Book20 />,
  Psychology: <Cognitive20 />,
  Art: <WatsonHealthBrushFreehand20 />,
  SocialStudies: <Events20 />,
  'Creative Writing': <Pen20 />,
  Music: <Music20 />,
  Drama: <Theater20 />,
  Law: <RequestQuote20 />,
  'Political Science': <Finance20 />,
  Biology: <WatsonHealthDna20 />,
  Education: <Education20 />,
  'Health Education': <Stethoscope20 />,
  'Physical Education': <Basketball20 />,
  'Shop Class': <TaskTools20 />,
  Engineering: <Rocket20 />,
  Marketing: <Analytics20 />,
  Sociology: <Friendship20 />,
  'Chess Club': <Gamification20 />,
  'Science Club': <Chemistry20 />,
  'Debate Team': <Forum20 />,
  'Robotics Club': <Bot20 />,
  'Academic Decathlon': <Education20 />,
  'Math Club': <Calculation20 />,
  'Coding Club': <Code20 />,
  'Science Olympiad': <Chemistry20 />,
  'Philosophy Club': <ShareKnowledge20 />,
  'Student Government': <Finance20 />,
  'Business Club': <NetworkEnterprise20 />,
  'Model UN': <EarthFilled20 />,
  'Mock Trial': <RequestQuote20 />,
  'Community Service Club': <Friendship20 />,
  'Writing Club': <Pen20 />,
  'Art Club': <PaintBrush20 />,
  'Environmental Club': <EarthFilled20 />,
  'Peer Counseling': <Friendship20 />,
  'Creative Writing Club': <Pen20 />,
  'Music Club': <Music20 />,
  'Peer Mentoring': <Friendship20 />,
  'Public Speaking Club': <Microphone20 />,
  'Volunteer Groups': <Events20 />,
  'History Club': <Hourglass20 />,
  JROTC: <UserMilitary20 />,
  'Nursing Health Club': <Hospital20 />,
  'Book Club': <Book20 />,
  'Law Club': <RequestQuote20 />,
  'Sports Teams': <Basketball20 />,
  'Auto Club': <Taxi20 />,
  'Outdoor Adventure Club': <Tree20 />,
  'Tech Club': <Chip20 />,
  'Photography Club': <Aperture20 />,
  'Dance Club': <FaceActivated20 />,
  Cheerleading: <Trophy20 />,
  'Dance Team': <FaceActivated20 />,
};

FeatureLists.propTypes = {
  subjects: propTypes.array,
  extracurricular: propTypes.array,
};
List.propTypes = {
  name: propTypes.string,
  items: propTypes.array,
};
