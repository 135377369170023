import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionItem, CoedStatus, LearningEnvironments, SupportPrograms } from '..';
import { useGetFeaturesQuery } from 'redux/api/school-api';
import { CloseOutline24 } from '@carbon/icons-react';
import { setFeature, toggleFilters } from 'redux/slices/school-results.slice';
import { transformGroupFeatures } from '../../utils/transform-group-features';
import { fuzzyMatchFeatures } from '../../utils/curriculum-categories';
import styles from './column-filters.module.scss';

export const ColumnFilters = ({ className, style, children }) => {
  const dispatch = useDispatch();
  const handleFeatureSelection = (id) => dispatch(setFeature(id));

  const { data, isLoading } = useGetFeaturesQuery();

  const { coed, groups, specialNeeds, curriculum } = useMemo(() => {
    const groups = transformGroupFeatures(data);

    // fuzz match
    const matches = fuzzyMatchFeatures(groups.curriculumIds);
    return { ...groups, curriculum: matches.categorized };
  }, [data]);

  const { featuresByUser } = useSelector((state) => state.schoolResults);

  if (isLoading) return 'Loading';

  return (
    <div className={className} style={style}>
      <div className={styles.filtersHeader}>
        <h3>Filters</h3>
        <button onClick={() => dispatch(toggleFilters())}>
          <CloseOutline24 />
        </button>
      </div>

      {children}

      <LearningEnvironments />

      <SupportPrograms
        handleFeatureSelection={handleFeatureSelection}
        featuresSelection={featuresByUser}
        specialNeedsFeatures={specialNeeds.features}
      />

      <div className={styles.container_type}>
        <h4 className={styles.title_filter}>Coed Status</h4>
        <CoedStatus coed={coed} handleFeatureSelection={handleFeatureSelection} featuresSelection={featuresByUser} />
      </div>

      <AccordionItem title="Teaching Methods" listGroups={groups['Teaching Methods']} />
      <AccordionItem title="Curriculum" listGroups={curriculum} />
      <AccordionItem title="Extracurricular" listGroups={groups.Extracurricular} />
      <AccordionItem title="Programs" listGroups={groups.Programs} />
      <AccordionItem title="Religion" listGroups={groups.Religion} />
    </div>
  );
};
ColumnFilters.propTypes = {
  className: propTypes.string,
  style: propTypes.object,
  children: propTypes.node,
};
