import { useMemo } from 'react';
import { useToggle } from 'hooks/useToggle';
import gradesArray from 'util/grades-array';
import { ChevronDown20, ChevronUp20 } from '@carbon/icons-react';
import propTypes from 'prop-types';
import { useClickOutside } from 'hooks/useClickOutside';
import styles from './grades-dropdown.module.scss';

export const GradeDropdown = ({ handleGrade, gradeQuery }) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const gradesOptions = useMemo(() => ['all', ...gradesArray], []);

  const ref = useClickOutside(() => {
    if (isOpen) toggleOpen();
  });

  return (
    <section className={styles.gradesSection}>
      <p>Grades:</p>
      <div className={styles.gradesDrop} ref={ref}>
        <button onClick={toggleOpen} className={styles.buttonToggle}>
          <span>{gradeQuery.replace(/,/g, ', ')}</span>
          {!isOpen ? <ChevronDown20 /> : <ChevronUp20 />}
        </button>
        {isOpen && (
          <div className={styles.gradesList}>
            {gradesOptions.map((grade) => (
              <label key={grade} htmlFor={grade} className={styles.item}>
                <span>{grade}</span>
                <input
                  style={{ height: '12px', width: 'auto' }}
                  type="checkbox"
                  id={grade}
                  name={grade}
                  value={grade}
                  onChange={() => handleGrade(grade)}
                  checked={gradeQuery.split(',').includes(grade)}
                />
              </label>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
GradeDropdown.propTypes = {
  handleGrade: propTypes.func.isRequired,
  gradeQuery: propTypes.string.isRequired,
};
