import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { getStudent } from '../../utils/get-student';
import { useSelector, useDispatch } from 'react-redux';
import { ShareAltOutlined } from '@ant-design/icons';
import { t } from 'components/translation/Translation';
import { Buttonv2 as Button } from 'schola-components';
import { toggleShare } from 'redux/slices/school-profile.slice';
import { ModalDesktop } from 'components/pages/school-profile/components/modal-desktop/modal-desktop';
import ShareModal from 'components/share-modal/share-modal';
import propTypes from 'prop-types';
import styles from './header.module.scss';

export const Header = ({ personality, caption, pathname }) => {
  const dispatch = useDispatch();
  const student = getStudent();

  const { schoolsPath, overviewPath } = useSelector((state) => state.schoolResults);

  const handleShare = () => dispatch(toggleShare());

  const { isShareSchoolOpen } = useSelector((state) => state.profile);

  return (
    <div className={styles.header}>
      <div className={styles.header_container}>
        <div className={styles.logo}>
          <div>
            <img src="https://static.schola.com/scholamatch/Logo-sm-results.svg" alt="ScholaMatch Results" />
          </div>
          <div>
            <Button icon color="secondary" size="small" className={styles.button_share} onClick={handleShare}>
              <ShareAltOutlined style={{ fontSize: '16px' }} />
              <p>{t('schoolProfile:gallery.share')}</p>
            </Button>
          </div>
        </div>
        <div className={styles.title_scout}>
          <div>
            <h1 className="font-700">
              <span className="underline">{student}</span> is a{' '}
              <span className={styles.personality}>{personality}</span>
            </h1>
            <p>{caption}</p>
          </div>
          <div className={styles.scout}>
            <img
              width="170"
              src={`https://static.schola.com/personality/quiz-scout/${personality.toLowerCase()}.svg`}
              alt="Personality Scout"
            />
          </div>
        </div>
      </div>
      <div className={styles.tabContainer}>
        <div className={styles.tabs}>
          <Tab pathname={pathname} pathKey="overview" to={overviewPath}>
            Overview
          </Tab>
          <Tab pathname={pathname} pathKey="schools" to={schoolsPath}>
            Schools for you
          </Tab>
        </div>
      </div>
      {isShareSchoolOpen && (
        <ModalDesktop
          isVisible={isShareSchoolOpen}
          onClose={handleShare}
          title="Share Your Personality Results"
          className="share-modal">
          <ModalDesktop.Body>
            <ShareModal />
          </ModalDesktop.Body>
        </ModalDesktop>
      )}
    </div>
  );
};

const Tab = ({ children, pathname, pathKey, to }) => {
  const tabStyles = {
    [styles.tab]: true,
    [styles.selected]: pathname.includes(pathKey),
    [styles.default]: !pathname.includes(pathKey),
    [styles.results_tab]: pathname.includes('schools'),
  };

  return (
    <Link to={to} className={classNames(tabStyles)}>
      {children}
    </Link>
  );
};
Tab.propTypes = {
  children: propTypes.node.isRequired,
  pathname: propTypes.string.isRequired,
  pathKey: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
};
Header.propTypes = {
  personality: propTypes.string,
  caption: propTypes.string,
  pathname: propTypes.string,
};
