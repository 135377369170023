import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link32, Email32, LogoFacebook32, LogoTwitter32, LogoLinkedin32 } from '@carbon/icons-react';
import { t } from 'components/translation/Translation';
import styles from './share-modal.module.scss';

const ShareModal = () => {
  const [state, setState] = useState({
    saving: false,
    submitted: false,
    failed: false,
  });
  /* const buildSchoolUri = `${window.location.origin}${window.location.pathname}/${window.location.search}`; */
  /* const buildSchoolUri = () => {
    return `${window.location.origin}${window.location.pathname}${window.location.search}`;
  }; */
  const buildSchoolUri = () => {
    return window.location.href;
  };

  const handleShareLinkClick = (serviceName) => {
    // Implementar lógica de seguimiento de click si es necesario
  };

  const handleCopyLink = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(buildSchoolUri()).then(
      () => {
        setState({ ...state, saving: true, submitted: true, failed: false });
      },
      () => {
        setState({ ...state, saving: true, submitted: true, failed: true });
      }
    );
  };

  const renderTwitterShareButton = () => (
    <a
      onClick={() => handleShareLinkClick('twitter')}
      className={styles.modalButton}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://twitter.com/intent/tweet?url=${buildSchoolUri()}`}>
      <div className={styles.shareIconContainer}>
        <LogoTwitter32 />
      </div>
      <p className={styles.modalButtonText}>Tweet</p>
    </a>
  );

  const renderEmailShareButton = () => (
    <a
      onClick={() => handleShareLinkClick('email')}
      className={styles.modalButton}
      target="_blank"
      rel="noopener noreferrer"
      href={`mailto:?subject=${encodeURIComponent('I want to share my results with you')}&body=${encodeURIComponent(
        'Hi there, these are my results: '
      )}${encodeURIComponent(buildSchoolUri())}`}>
      <div className={styles.shareIconContainer}>
        <Email32 />
      </div>
      <p className={styles.modalButtonText}>Email</p>
    </a>
  );

  const renderFacebookShareButton = () => (
    <a
      onClick={() => handleShareLinkClick('facebook')}
      className={styles.modalButton}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.facebook.com/sharer/sharer.php?u=${buildSchoolUri()}`}>
      <div className={styles.shareIconContainer}>
        <LogoFacebook32 />
      </div>
      <p className={styles.modalButtonText}>Facebook</p>
    </a>
  );

  const renderLinkedinShareButton = () => (
    <a
      onClick={() => handleShareLinkClick('linkedin')}
      className={styles.modalButton}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${buildSchoolUri()}`}>
      <div className={styles.shareIconContainer}>
        <LogoLinkedin32 />
      </div>
      <p className={styles.modalButtonText}>LinkedIn</p>
    </a>
  );

  const renderCopyLinkButton = () => (
    <a
      onClick={handleCopyLink}
      className={styles.modalButton}
      target="_blank"
      rel="noopener noreferrer"
      href={buildSchoolUri()}>
      <div className={styles.shareIconContainer}>
        <Link32 />
      </div>
      <p className={styles.modalButton_text}>{t('schoolProfile:gallery.copyLink')}</p>
    </a>
  );

  const { saving, submitted, failed } = state;

  return (
    <div className={styles.scholaShare} data-testid="shareModal">
      <div className={styles.scholaShare__action}>
        {saving && (
          <div
            className={`${styles.saveStatus} ${
              saving
                ? styles.show
                : submitted
                ? `${styles.show} ${styles.saved}`
                : failed
                ? `${styles.show} ${styles.failed}`
                : ''
            }`}>
            {submitted && (
              <span>
                <i className="fa fa-check" />
                &nbsp; {t('schoolProfile:gallery.copiedClipboard')}
              </span>
            )}
            {failed && (
              <span>
                <i className="fa fa-close" />
                &nbsp; {t('schoolProfile:gallery.failed')}
              </span>
            )}
          </div>
        )}
        {renderFacebookShareButton()}
        {renderEmailShareButton()}
        {/* {renderTwitterShareButton()}
        {renderLinkedinShareButton()} */}
        {renderCopyLinkButton()}
      </div>
    </div>
  );
};

ShareModal.propTypes = {
  schoolId: PropTypes.number,
};

export default ShareModal;
