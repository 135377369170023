import React from 'react';
import propTypes from 'prop-types';

export const RadarChart = ({ data }) => {
  // Calculate points on the radar
  const getPoints = (values, radius) => {
    const points = values.map((value, i) => {
      const angle = (Math.PI * 2 * i) / values.length - Math.PI / 2;
      const x = radius * Math.cos(angle) * (value / 100);
      const y = radius * Math.sin(angle) * (value / 100);
      return `${x},${y}`;
    });
    return points.join(' ');
  };

  // Generate background grid lines
  const generateGrid = (radius, segments) => {
    const gridLines = [];
    for (let i = 1; i <= segments; i++) {
      const points = [];
      const ratio = i / segments;
      for (let j = 0; j <= data.length; j++) {
        const angle = (Math.PI * 2 * j) / data.length - Math.PI / 2;
        const x = radius * Math.cos(angle) * ratio;
        const y = radius * Math.sin(angle) * ratio;
        points.push(`${x},${y}`);
      }
      gridLines.push(
        <polyline
          key={i}
          points={points.join(' ')}
          fill="none"
          stroke="#e5e7eb"
          strokeWidth="0.5"
          strokeDasharray="4 2"
        />
      );
    }
    return gridLines;
  };

  // Generate axis lines and labels
  const generateAxes = (radius) => {
    return data.map((item, i) => {
      const angle = (Math.PI * 2 * i) / data.length - Math.PI / 2;
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      const labelX = (radius + 25) * Math.cos(angle);
      const labelY = (radius + 10) * Math.sin(angle);

      return (
        <g key={i}>
          <line x1="0" y1="0" x2={x} y2={y} stroke="#e5e7eb" strokeWidth="0.5" />
          <text
            x={labelX}
            y={labelY}
            textAnchor="middle"
            dominantBaseline="middle"
            className="text-xs text-gray-600"
            color="red"
            style={{ fontSize: '8px' }}>
            {item.skill}
          </text>
        </g>
      );
    });
  };

  const radius = 100;
  const viewBoxSize = radius * 3.2;
  const values = data.map((item) => item.value);

  return (
    <div className="w-full max-w-xl">
      <svg viewBox={`${-viewBoxSize / 2} ${-viewBoxSize / 2} ${viewBoxSize} ${viewBoxSize}`} className="w-full h-full">
        {/* Background grid */}
        {generateGrid(radius, 4)}

        {/* Axes and labels */}
        {generateAxes(radius)}

        {/* Data polygon */}
        <polygon points={getPoints(values, radius)} fill="#9bdcc0" fillOpacity="0.5" stroke="#10b981" strokeWidth="2" />
      </svg>
    </div>
  );
};
RadarChart.propTypes = {
  data: propTypes.array,
};
