import { createSlice } from '@reduxjs/toolkit';

const coedMixedId = 501;
const inPersonId = 513;

const initialState = {
  featuresPreselected: [],
  featuresByUser: [],
  learningEnv: inPersonId,
  coedSelection: coedMixedId,
  supportPrograms: [],
  student: '',
  isOpenFilters: false,
  isMapOpen: false,
  overviewPath: '',
  schoolsPath: '',
  isMultipleContact: false,
  schoolToContact: {},
  multipleSchoolIds: [],
};

export const schoolResults = createSlice({
  name: 'schoolResults',
  initialState,
  reducers: {
    setDefaultFeatures: (state, { payload }) => {
      state.featuresPreselected = [...state.featuresPreselected, ...payload];
    },
    addFeature: (state, { payload }) => {
      state.featuresByUser = [...state.featuresByUser, payload];
    },
    removeFeature: (state, { payload }) => {
      state.featuresByUser = state.featuresByUser.filter((item) => item !== payload);
    },
    setFeature: (state, { payload }) => {
      if (!state.featuresByUser.includes(payload)) {
        state.featuresByUser = [...state.featuresByUser, payload];
      } else {
        state.featuresByUser = state.featuresByUser.filter((item) => item !== payload);
      }
    },
    setMultipleFeatures: (state, { payload }) => {
      state.featuresByUser = [...state.featuresByUser, ...payload];
    },
    removeMultipleFeatures: (state, { payload }) => {
      state.featuresByUser = state.featuresByUser.filter((id) => !payload.includes(id));
    },
    setStudent: (state, { payload }) => {
      state.student = payload;
    },
    toggleFilters: (state) => {
      state.isOpenFilters = !state.isOpenFilters;
    },
    toggleMap: (state) => {
      state.isMapOpen = !state.isMapOpen;
    },
    setPaths: (state, { payload }) => {
      state.schoolsPath = payload.schoolsPath;
      state.overviewPath = payload.overviewPath;
    },
    setSchoolToContact: (state, { payload }) => {
      state.schoolToContact = payload;
    },
    setMultipleContact: (state, { payload }) => {
      state.isMultipleContact = payload;
    },
    setMultipleSchoolsIds: (state, { payload }) => {
      state.multipleSchoolIds = payload;
    },
    setLearningEnv: (state, { payload }) => {
      if (payload.id !== state.learningEnv) {
        state.learningEnv = payload.id;
        state.featuresByUser = [...state.featuresByUser, payload.id];
        state.featuresByUser = state.featuresByUser.filter((id) => !payload.missing.includes(id));
      }
    },
    setCoed: (state, { payload }) => {
      state.coedSelection = state.coedSelection === payload ? null : payload;
    },
    setSupportPrograms: (state, { payload = [] }) => {
      if (!state.supportPrograms.some((id) => payload.includes(id))) {
        state.supportPrograms = [...state.supportPrograms, ...payload];
      } else {
        state.supportPrograms = state.supportPrograms.filter((id) => !payload.includes(id));
      }
    },
  },
});

export default schoolResults.reducer;

export const {
  addFeature,
  removeFeature,
  setFeature,
  setMultipleFeatures,
  removeMultipleFeatures,
  setDefaultFeatures,
  setLearningEnv,
  setCoed,
  setSupportPrograms,
  setStudent,
  toggleFilters,
  toggleMap,
  setPaths,
  setMultipleContact,
  setSchoolToContact,
  setMultipleSchoolsIds,
} = schoolResults.actions;
