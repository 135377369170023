import { useDispatch, useSelector } from 'react-redux';
import { GroupFeatures } from '..';
import { setLearningEnv } from 'redux/slices/school-results.slice';

export const LearningEnvironments = () => {
  const learningEnvironments = [
    { id: 513, name: 'In-Person Only' },
    { id: 408, name: 'Hybrid (Online + In-Person)' },
    { id: 447, name: 'Homeschool' },
    { id: 446, name: 'Homeschool with On-Site Support' },
    { id: 342, name: 'Boarding School' },
    { id: 9, name: 'Online Only' },
  ];

  const { learningEnv } = useSelector((state) => state.schoolResults);

  /**
    Online Only - Fully virtual classes, accessible from home or anywhere with internet.
    In-Person Only - Traditional on-campus learning.
    Hybrid (Online + In-Person) - A mix of online and on-campus classes.
    Homeschool - Parent-directed education at home.
    Homeschool with On-Site Support - Home-based learning combined with some on-campus programs.
    Boarding School - Full-time on-campus living and learning.
   */

  const dispatch = useDispatch();
  const handleLearnEnv = (id) => {
    const missing = learningEnvironments.map((item) => item.id).filter((item) => item !== id);

    if (id !== learningEnv) {
      dispatch(setLearningEnv({ id, missing }));
    }
  };

  return (
    <GroupFeatures
      title="School Learning Environment"
      features={learningEnvironments}
      featuresSelection={[learningEnv] || []}
      handleFeatureSelection={handleLearnEnv}
    />
  );
};
