import { useSelector } from 'react-redux';
import { useToggle } from 'hooks/useToggle';
import { Add16, Subtract16 } from '@carbon/icons-react';
import propTypes from 'prop-types';
import { GroupChips } from '..';
import styles from './accordion-item.module.scss';

export const AccordionItem = ({ title, listGroups }) => {
  const { featuresPreselected, featuresByUser } = useSelector((state) => state.schoolResults);
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div>
      <button className={styles.accordionHeader} onClick={toggleOpen}>
        <h3>{title}</h3>
        {isOpen ? <Subtract16 /> : <Add16 />}
      </button>

      {isOpen &&
        listGroups.map((group, idx) => (
          <GroupChips
            key={`${group.name} - ${idx}`}
            featuresSelection={[...featuresPreselected, ...featuresByUser]}
            {...group}
          />
        ))}
    </div>
  );
};
AccordionItem.propTypes = {
  title: propTypes.string.isRequired,
  listGroups: propTypes.array,
};
