import { Checkbox } from '..';
import { allPublics, schoolTypes } from '../../utils/constants';
import propTypes from 'prop-types';
import styles from '../column-filters/column-filters.module.scss';

export const SchoolTypeSelector = ({ schoolType, handleType }) => {
  return (
    <div>
      {schoolTypes.map((type) => (
        <Checkbox
          key={type.value}
          className={styles[type.level]}
          label={type.label}
          id={type.value}
          onChange={() => handleType(type.value)}
          isChecked={
            type.value === 'public'
              ? allPublics.every((t) => schoolType.split(',').includes(t))
              : schoolType.split(',').includes(type.value)
          }
        />
      ))}
    </div>
  );
};
SchoolTypeSelector.propTypes = {
  schoolType: propTypes.string.isRequired,
  handleType: propTypes.func.isRequired,
};
