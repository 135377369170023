import { CloseCircleOutlined } from '@ant-design/icons';
import styles from './group-chips.module.scss';
import { useToggle } from 'hooks/useToggle';
import { useDispatch } from 'react-redux';
import { removeMultipleFeatures, setFeature, setMultipleFeatures } from 'redux/slices/school-results.slice';
import propTypes from 'prop-types';

export const GroupChips = ({ featuresSelection, ...group }) => {
  const dispatch = useDispatch();
  const handleFeatureSelection = (id) => dispatch(setFeature(id));
  const [completeView, toggleCompleteView] = useToggle(false);

  const [isSelectedAll, toggleSelectedAll] = useToggle(false);

  const handleSelectAllGroup = () => {
    toggleSelectedAll();

    const ids = group.features.map(({ id }) => id);

    if (!isSelectedAll) {
      dispatch(setMultipleFeatures(ids));
    } else {
      dispatch(removeMultipleFeatures(ids));
    }
  };

  return (
    <div className={styles.sectionGroup}>
      <h5 className={styles.title}>{group.name}</h5>
      <div className={styles.filters}>
        <div className={`${styles.group} ${completeView ? styles.autoHeight : styles.fixHeight}`}>
          <Chip
            name={`${!isSelectedAll ? 'Select' : 'Deselect'} All`}
            id="all"
            onClick={handleSelectAllGroup}
            isSelected={isSelectedAll}
          />

          {group.features.slice(0, completeView ? undefined : 3).map(({ id, name }) => (
            <Chip
              key={id}
              id={id}
              name={name}
              onClick={() => handleFeatureSelection(id)}
              isSelected={featuresSelection?.includes(id)}
            />
          ))}
        </div>
        {group.features.length > 5 && (
          <button className={styles.seeMore} onClick={toggleCompleteView}>
            See {!completeView ? 'more' : 'less'}
          </button>
        )}
      </div>
    </div>
  );
};

const Chip = ({ name, id, onClick, isSelected }) => {
  return (
    <button id={id} className={`${styles.filterChip} ${isSelected ? styles.selected : ''}`} onClick={onClick}>
      <span>{name}</span>
      {isSelected && <CloseCircleOutlined />}
    </button>
  );
};
GroupChips.propTypes = {
  group: propTypes.object.isRequired,
  featuresSelection: propTypes.arrayOf(propTypes.string),
};
Chip.propTypes = {
  name: propTypes.string.isRequired,
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  onClick: propTypes.func,
  isSelected: propTypes.bool,
};
