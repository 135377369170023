import { useEffect, useState } from 'react';
import { planName } from 'util/schoolUtils';
import { useGetFeaturesQuery } from 'redux/api/school-api';
import { useSearchSchools } from './useSearchSchools';
import { useSelector } from 'react-redux';
import { allTypes } from '../utils/constants';

export const useSchoolMatch = ({ query, gradeQuery, schoolType }) => {
  const { featuresPreselected, featuresByUser, coedSelection, supportPrograms, learningEnv } = useSelector(
    (state) => state.schoolResults
  );
  const { data: featuresGeneral, isLoading, isSuccess } = useGetFeaturesQuery();

  const { rawSchools, successSchools, loadingSchools, errorSchools, isFetching } = useSearchSchools({ query });
  const [schools, setSchools] = useState([]);

  const [sponsored, setSponsored] = useState([]);

  const defaultFeatures = new Set(featuresPreselected);
  const userSelectionFeatures = new Set(featuresByUser) || [];

  // separate responsabilities for filtering schools based on url change
  useEffect(() => {
    if (successSchools) {
      /** 1. Filter by grades and types selected */
      let filteredSchools = getFilteringSchools({
        rawSchools,
        gradeQuery,
        schoolType,
        learningEnv,
        coed: coedSelection,
        supportPrograms,
      });

      /** 2. Assign features matched by school according to current selection */
      if (isSuccess) {
        filteredSchools = getAssignMatches({
          schools: filteredSchools,
          featuresGeneral,
          defaultFeatures,
          userSelectionFeatures,
        });
      }

      /** 3. Apply sorting based of number of features matched */
      filteredSchools = filteredSchools.sort((a, b) => b.score - a.score);

      /** 4. Separate top 3 from the rest of the list */
      const organicSchools = filteredSchools.slice(0, 3);
      const remainingSchools = filteredSchools.slice(3);

      /** 5. Select schools with plan and remove it from remainingSchools list, max 3 spots, have to be out of the top organic 3 */
      let sponsoredSchools = [];

      for (let index = 0; index < 3; index++) {
        const findProSchool = remainingSchools.findIndex((school) => planName(school) !== 'scholabasics');
        if (findProSchool > -1) {
          sponsoredSchools.push(remainingSchools[findProSchool]);
          remainingSchools.splice(findProSchool, 1);
        }
      }

      setSponsored(sponsoredSchools);
      setSchools([...organicSchools, ...remainingSchools]);
    }
  }, [
    rawSchools,
    gradeQuery,
    schoolType,
    featuresPreselected,
    featuresByUser,
    featuresGeneral,
    learningEnv,
    coedSelection,
  ]);

  return {
    schools,
    sponsored,
    foundSchools: schools.length + sponsored.length,
    loadingSchools,
    successSchools,
    errorSchools,
    isFetching,
  };
};

const getFilteringSchools = ({ rawSchools, gradeQuery, schoolType, learningEnv, coed, supportPrograms }) => {
  let filteredSchools = [...rawSchools];

  // Grade filtering with early return for optimization
  if (gradeQuery !== 'all') {
    filteredSchools = filteredSchools.filter((school) => school.grades.some((g) => gradeQuery.split(',').includes(g)));
  }

  // Determine school types
  const onlineFeatureId = 9;
  const isOnline = Number(learningEnv) === onlineFeatureId;
  let types = schoolType === 'all' ? allTypes : schoolType;

  // Add virtual/online type if online learning environment is selected
  if (isOnline) {
    types = types.concat(',virtual/online');
  }

  // Filter by school types
  filteredSchools = filteredSchools.filter((school) => types.split(',').includes(school.type));

  // Additional online school filtering
  if (isOnline) {
    filteredSchools = filteredSchools.filter(
      (school) => school.featuresBySchool.includes(onlineFeatureId) || school.type === 'virtual/online'
    );
  }

  // Additional coed status filtering
  const coedMixedId = 501;
  if (coed !== coedMixedId) {
    filteredSchools = filteredSchools.filter((school) => school.featuresBySchool.includes(coed));
  }

  // Additional support programs filtering
  if (supportPrograms.length > 0) {
    filteredSchools = filteredSchools.filter((school) =>
      supportPrograms.some((id) => school.featuresBySchool.includes(id))
    );
  }

  return filteredSchools;
};

const getAssignMatches = ({ schools, featuresGeneral, defaultFeatures, userSelectionFeatures }) => {
  const matchesBySchool = schools.map((school) => {
    const matches = matchFeatures({ featuresSchool: school.featuresBySchool, featuresToMatch: defaultFeatures });
    const matchesByUser = matchFeatures({
      featuresSchool: school.featuresBySchool,
      featuresToMatch: userSelectionFeatures,
    });
    let score = 0;
    let featuresMatched = [];

    // score 1 for each feature pre-selected by personality
    matches.forEach((featureId) => {
      const feature = getFeature(featureId, featuresGeneral);
      if (feature) {
        featuresMatched.push({
          ...feature,
          isByUser: false,
        });
        score++;
      }
    });
    // score 100 for each feature selected manually by the user
    matchesByUser.forEach((featureId) => {
      const feature = getFeature(featureId, featuresGeneral);
      if (feature) {
        featuresMatched = [
          {
            ...feature,
            isByUser: true,
          },
          ...featuresMatched,
        ];
        score += 100;
      }
    });
    return {
      ...school,
      featuresMatched,
      score,
    };
  });
  return matchesBySchool;
};

const matchFeatures = ({ featuresSchool, featuresToMatch }) => {
  return featuresSchool.filter((feature) => featuresToMatch.has(feature));
};

const getFeature = (featureId, featuresList) => {
  for (const group of featuresList) {
    const feature = group.features.find((f) => f.id === featureId);
    if (feature) {
      return {
        id: feature.id,
        name: feature.name,
        groupId: group.id,
        groupName: group.name,
      };
    }
  }

  return null;
};
