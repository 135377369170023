import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { CalendarHeatMap16 } from '@carbon/icons-react';
import styles from './horizontal-card.module.scss';
import { handleRouteSchool } from '../utils';
import { SponsoredTag } from '../vertical-card/vertical-card';
import { useToggle } from 'hooks/useToggle';
import { FeaturesMatched } from 'components/features-matched/features-matched';
import Button from 'components/button/Button';
import { SchoolInfo } from '../school-info/school-info';

export const HorizontalCard = ({ school, rank, isSponsored, handleContact }) => {
  const {
    name,
    address,
    city,
    state,
    distance,
    type,
    grades,
    open_enrollment: isOpenEnrollment,
    claimed,
    featuresMatched,
    profile_image: image,
    states_served: statesServed,
  } = school;

  const [completeView, toggleCompleteView] = useToggle(false);

  return (
    <div className={styles.horizontalCard}>
      <div className={styles.image_container}>
        {isOpenEnrollment && <EnrollingTag />}
        {isSponsored && <SponsoredTag />}
        <div className={styles.image}>
          <img src={image || 'https://static.schola.com/profile/placeholderbig.png'} alt="Schola Image" />
        </div>
      </div>
      <div className={styles.horizontal_school_info_content}>
        <div>
          {rank && <div className={styles.horizontal_bestmatch}>{rank}</div>}
          <div className={styles.horizontal_school_name_favorite}>
            <div className={styles.horizontal_school_name}>
              <h5>{name}</h5>
              {claimed && <CheckCircleFilled style={{ color: '#0099CC' }} />}
            </div>
          </div>

          <SchoolInfo
            address={`${address}, ${city}, ${state}`}
            distance={distance}
            grades={grades}
            type={type}
            statesServed={statesServed}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button fullWidthMobile color="primary" onClick={handleContact}>
            Request Information
          </Button>
          <Button fullWidthMobile color="secondary" onClick={() => handleRouteSchool(school)}>
            View Profile
          </Button>
        </div>
        <FeaturesMatched
          featuresMatched={featuresMatched}
          className={completeView && styles.matchOverlay}
          completeView={completeView}
          toggleCompleteView={toggleCompleteView}
          showElements={3}
        />
      </div>
    </div>
  );
};

const EnrollingTag = () => {
  return (
    <div className={styles.horizontal_card_enrolling}>
      <CalendarHeatMap16 />
      Enrolling
    </div>
  );
};
