import React from 'react';
import { Close32 } from '@carbon/icons-react';
import { usePreventBodyScroll } from 'components/pages/parent-portal/track/hooks';
import classNames from 'classnames';
import styles from './modal-desktop.module.scss';

export const ModalDesktop = ({ children, className, isVisible, onClose, title, showSecureImage = true }) => {
  if (!isVisible) return null;

  usePreventBodyScroll(isVisible);

  const modalClasses = {
    [styles.background]: true,
    [className]: className,
  };

  return (
    <div className={classNames(modalClasses)}>
      <div className={styles.modal}>
        <Header title={title} onClose={onClose} showSecureImage={showSecureImage} />
        {children}
      </div>
    </div>
  );
};

const Header = ({ title, onClose, showSecureImage }) => {
  return (
    <div className={styles.header}>
      <div className={styles.closeContainer}>
        <button className={styles.close} onClick={onClose}>
          <Close32 />
        </button>
      </div>
      <div className={title ? `${styles.nortonContainer}` : `${styles.nortonContainer_flexend}`}>
        {title && <h3>{title}</h3>}
        {showSecureImage && (
          <div className={`${styles.norton} norton-logo`}>
            <img
              src="https://static.schola.com/profile/norton.webp"
              alt="Norton Symantec"
              width="120"
              height="75"
              loading="lazy"
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Body = ({ children, className }) => {
  return <div className={`${styles.body} ${className}`}>{children}</div>;
};

const BarContainer = ({ children }) => {
  return <>{children}</>;
};

const Footer = ({ children, className }) => {
  return <div className={`${styles.footer} ${className}`}>{children}</div>;
};

ModalDesktop.Header = Header;
ModalDesktop.Body = Body;
ModalDesktop.Footer = Footer;
ModalDesktop.BarContainer = BarContainer;
