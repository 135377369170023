import { useMemo } from 'react';
import { Add16, Subtract16 } from '@carbon/icons-react';
import styles from './features-matched.module.scss';
import { ChipFeature } from 'components/pages/schola-match-results/components';
import { getFeatureIcon } from 'components/pages/school-profile-page/utils/getFeatureIcon';

export const FeaturesMatched = ({ featuresMatched, completeView, toggleCompleteView, showElements = 2, className }) => {
  if (featuresMatched?.length === 0) return null;

  const hidden = useMemo(() => {
    const total = featuresMatched?.length;
    const hidden = total - showElements;

    return hidden;
  }, [featuresMatched]);

  return (
    <div className={`${styles.school_match_overview} ${className || ''}`}>
      <div className={styles.overview} onClick={toggleCompleteView}>
        <h6>Features Matching Your Search</h6>
        {featuresMatched?.length > showElements && <span>{!completeView ? <Add16 /> : <Subtract16 />}</span>}
      </div>
      <div className={`${completeView ? styles.autoHeight : styles.fixHeight}`}>
        <div className={styles.chipsGrid}>
          {featuresMatched?.slice(0, completeView ? undefined : showElements).map((feature) => (
            <ChipFeature
              key={feature.id}
              className={`${styles.chip_match} ${feature.isByUser && styles.userSelection}`}>
              {getFeatureIcon(feature.groupName)} {feature.name} {feature.isByUser && '*'}
            </ChipFeature>
          ))}
          {hidden > 0 && !completeView && (
            <ChipFeature className={styles.chip_plus} onClick={toggleCompleteView}>
              <Add16 />
              {hidden}
            </ChipFeature>
          )}
        </div>
      </div>
    </div>
  );
};
