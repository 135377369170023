import { gradesLabel } from '../utils';
import { Location16 } from '@carbon/icons-react';
import styles from './school-info.module.scss';

export const SchoolInfo = ({ address, distance, type, grades, statesServed }) => {
  const isOnline = type === 'virtual/online';

  return (
    <div className={styles.school_info}>
      <div className={styles.location}>
        {!isOnline ? (
          <>
            <p>{address}</p>
            <p className={styles.miles}>
              <Location16 /> {distance.toFixed(1)} miles away
            </p>
          </>
        ) : (
          statesServed && <span>States Served: {statesServed?.replace(/,/g, ', ')}</span>
        )}
      </div>
      <BasicInfo grades={grades} type={type} />
    </div>
  );
};

export const BasicInfo = ({ type, grades }) => {
  return (
    <div>
      {type} | {gradesLabel(grades)} Grade
    </div>
  );
};
